// app/components/Logo.tsx
import Image from 'next/image';
import { Link as I18bLink } from '@/i18n/routing';

export default function Logo() {
  return (
    <I18bLink href="/">
      <div aria-label="Home">
        <Image
          src="/logo/yongsancats_square_logo.svg"
          alt="YongsanCats Logo"
          width={1000} // Adjust width as needed
          height={1000} // Adjust height as needed
          className="h-auto w-auto"
        />
      </div>
    </I18bLink>
  );
}
