import React, { useState } from 'react';
// import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useTranslations } from "next-intl"; // ✅ 다국어 지원 추가
import { Link } from "@/i18n/routing"; // ✅ next-intl 라우팅 적용
const NavigationMenu: React.FC = () => {
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const t = useTranslations("NavigationMenu"); // ✅ 번역 함수 추가
  const cleanedPathname = pathname.replace(/^\/(ko|en)\//, "/");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative flex items-center">
      {/* Mobile Menu Button */}
      <button
        onClick={toggleMenu}
        className="md:hidden text-black flex items-center ml-2"
        aria-label="Toggle menu"
      >
        {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        <span className="font-bold tracking-wider ml-2">EXPLORE</span>
      </button>

      {/* Desktop Navigation */}
      <div className="hidden md:flex md:items-center">
        <div className="flex flex-row px-5 pb-2 lg:pb-0 overflow-auto gap-4 w-full lg:w-max">
          {[
            // { label: 'HOME', href: '/' },
            { label: t("links.donate"), href: "/d" },
            { label: t("links.monthly_giving"), href: "/s" },
            { label: t("links.certificate"), href: "/c" },
            { label: t("links.about_us"), href: "/a" },
          ].map((item) => (
            <Link href={item.href} passHref key={item.label}>
              <div
                className={`text-sm font-semibold tracking-wider py-2 px-6 rounded-full whitespace-nowrap ${
                  cleanedPathname === item.href
                    ? 'bg-buttonYellow text-black'
                    : 'text-black bg-transparent'
                } hover:bg-buttonYellow hover:text-black transition-all duration-300`}
              >
                {item.label}
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      {isOpen && (
        <div className="absolute top-full left-0 w-48 bg-white shadow-lg z-50 md:hidden transition-all duration-300 ease-in-out">
          <div className="flex flex-col px-4 py-6 gap-4">
            {[
              { label: t("links.home"), href: '/' },
              { label: t("links.donate"), href: "/d" },
              { label: t("links.monthly_giving"), href: "/s" },
              { label: t("links.certificate"), href: "/c" },
              { label: t("links.about_us"), href: "/a" }
            ].map((item) => (
              <Link href={item.href} passHref key={item.label}>
                <div
                  onClick={closeMenu}
                  className={`text-base font-semibold tracking-wide py-3 px-6 rounded-md text-left ${
                    cleanedPathname === item.href
                      ? 'bg-customGreen text-white'
                      : 'text-gray-900 bg-transparent'
                  } hover:bg-customGreen hover:text-white transition-all duration-300`}
                  style={{ minWidth: '100%' }}
                >
                  {item.label}
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavigationMenu;
