"use client";

import { useState, useEffect, useRef } from "react";
import { useLocale } from "next-intl";
import { Link, usePathname } from "@/i18n/routing";
import { HiOutlineGlobe } from "react-icons/hi"; // ✅ Airbnb 스타일 지구본 추가

export default function LanguageSwitcher() {
  const locale = useLocale();
  const pathname = usePathname(); // ✅ 현재 경로 가져오기
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // 바깥 클릭 시 드롭다운 닫기
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  return (
    <div className="relative z-50" ref={dropdownRef}>
      {/* Airbnb 스타일 버튼 (지구본 아이콘만) */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center w-10 h-10 text-gray-700 hover:bg-gray-200 rounded-full transition duration-200"
      >
        <HiOutlineGlobe size={18} />
      </button>

      {/* 드롭다운 메뉴 (아래로 열림) */}
      {isOpen && (
        <div className="absolute right-0 top-full mt-2 w-32 bg-white text-gray-900 border border-gray-300 shadow-lg rounded-lg overflow-hidden">
          <Link
            href={pathname}
            locale="en"
            className={`flex items-center gap-2 px-4 py-2 text-sm hover:bg-gray-100 transition duration-200 ${
              locale === "en" ? "font-bold" : ""
            }`}
          >
            🇺🇸 English
          </Link>
          <Link
            href={pathname}
            locale="ko"
            className={`flex items-center gap-2 px-4 py-2 text-sm hover:bg-gray-100 transition duration-200 ${
              locale === "ko" ? "font-bold" : ""
            }`}
          >
            🇰🇷 한국어
          </Link>
        </div>
      )}
    </div>
  );
}
