import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/components/navbar/Header.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThirdwebProvider"] */ "/usr/src/app/app/thirdweb.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/usr/src/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
