// app/components/Header.tsx
"use client";  // 클라이언트 컴포넌트로 지정

import NavigationMenu from "./NavigationMenu";
import Logo from "../Logo";
import ConnectWallet from "../ConnectWallet";
import LanguageSwitcher from "../LanguageSwitcher";
// import SocialLinks from "./SocialLinks";

export default function Header() {
  // const twitterUrl = 'https://x.com/Souli_ERC20i';
  // const telegramUrl = 'https://t.me/pickitlab';
  // const whitepaperUrl = 'https://docs.pickitlab.com';
  // const openseaUrl = 'https://opensea.io/collection/moofy-1';

  return (
    <header className="bg-white shadow-md py-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="hidden md:block">
        <Logo />
        </div>

        {/* 가운데 네비게이션 메뉴 */}
        <NavigationMenu />

        {/* 오른쪽 로그인/로그아웃 버튼 */}
        <div className="flex gap-4 md:gap-8 items-center">
        {/* <SocialLinks twitterUrl={twitterUrl} telegramUrl={telegramUrl} whitepaperUrl={whitepaperUrl} openseaUrl={openseaUrl}/> */}
        <LanguageSwitcher /> 
        <ConnectWallet />

        </div>
      </div>
    </header>
  );
}
